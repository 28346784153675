.bid-line-evaluation {
  margin-bottom: 2rem;

  .afp-responsive-table {
    .usa-form-group {
      margin-top: 0;
      select {
        margin-top: 0;
      }
    }
    .usa-checkbox {
      margin-top: 0;
      .usa-checkbox__label {
        margin-top: 0.5rem;
      }
    }
    .award-check-all {
      margin-bottom: 28px;
    }
  }

  .bid-line-eval-filter-block {
    .bid-line-eval-filter-header {
      color: #005ea2;
      font-size: 1.5rem;
    }
    .bid-line-eval-filter-item {
      min-width: 15rem;
      .usa-form-group {
        margin-top: 0.5rem;
        .usa-label {
          font-weight: bold;
        }
      }
    }
  }
}

.reassign-modal {
  .usa-form-group {
    margin-top: 0.5rem;
    .usa-label {
      font-weight: bold;
    }
  }
}

.solicitation-details-container{
     .usa-input{
       min-width: 100%;
    }
    .usa-textarea{
        min-width: 100%;
    }
}

.padding-x-05 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.15rem;
}
.contract-line-template {
  .afp-responsive-table {
    .usa-form-group {
      margin-top: 0;
      input,
      select {
        margin-top: 0;
      }

      .usa-input-group {
        margin-top: 0;
      }
    }

    .usa-checkbox,
    .usa-radio {
      background: transparent;
    }

    th,
    td {
      padding: 0.5rem 0.75rem;
    }

    th.cell-expander {
      padding: 0.25rem 0 0 0.5rem;
    }

    th > div > button > span {
      margin-left: -2rem;
    }

    .input-prefix-disabled {
      .usa-input-group {
        background-color: #f6f6f6;
        border: none;
      }
    }
  }

  .table-empty-state {
    background-color: #f8f8f8;
    margin-top: -1.25rem;
    padding-top: 2rem;
  }

  .usa-accordion__content {
    margin-top: 0;
    padding-top: 0;
  }

  .edit-conflicts-modal {
    .usa-form-group {
      margin-top: 0;
      input,
      select {
        margin-top: 0;
      }

      .usa-input-group {
        margin-top: 0;
      }
    }
  }
}

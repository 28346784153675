.bid-line-item-details, .bid-sections-wrapper {
  .afp-responsive-table {
    .usa-form-group {
      margin-top: 0;
      input,
      select {
        margin-top: 0;
      }

      .usa-input-group {
        margin-top: 0;
      }
    }

    .usa-checkbox, .usa-radio {
      background: transparent;
    }

    th,
    td {
      padding: 0.5rem 0.75rem;
    }

    th.cell-expander {
      padding: 0.25rem 0 0 0.5rem;
    }

    th>div>button>span {
      margin-left: -2rem;
    }

    .input-prefix-disabled {
      .usa-input-group {
        background-color: #f6f6f6;
        border: none;
      }
    }
  }

  .table-empty-state {
    background-color: #f8f8f8;
    margin-top: -1.25rem;
    padding-top: 2rem;
  }

  .usa-accordion__content {
    margin-top: 0;
    padding-top: 0;
  }

  .edit-conflicts-modal {
    .usa-form-group {
      margin-top: 0;
      input,
      select {
        margin-top: 0;
      }

      .usa-input-group {
        margin-top: 0;
      }
    }
  }
}

.bid-line-highlighted-input-data {
  border: 2px solid #FFBE2E !important;
}

.bid-line-highlighted-input-data-only-button {
  > button {
    border: 2px solid #FFBE2E !important;
  }
}

.bid-sections-wrapper {
  .table__sub-component_cell {
    padding: 0;
  }
  .afp-responsive-table {
    margin: 0;
  }
  .afp-responsive-table tbody tr td, .afp-responsive-table tbody tr th {
    padding: 0.5rem 0.75rem;
  }
}

.highlight-tab-1 {
  > div.react-tabs > ul li:nth-child(1) {
    border: 2px solid var(--State-warning-light, #FEE685);
  }
}

.highlight-tab-2 {
  > div.react-tabs > ul li:nth-child(2) {
    border: 2px solid var(--State-warning-light, #FEE685);
  }
}

.highlight-tab-3 {
  > div.react-tabs > ul li:nth-child(3) {
    border: 2px solid var(--State-warning-light, #FEE685);
  }
}

.highlight-tab-4 {
  > div.react-tabs > ul li:nth-child(4) {
    border: 2px solid var(--State-warning-light, #FEE685);
  }
}

.highlight-tab-5 {
  > div.react-tabs > ul li:nth-child(5) {
    border: 2px solid var(--State-warning-light, #FEE685);
  }
}
.contract-line-listing-search-block {
  margin-bottom: 2rem;
  margin-top: 20px;
  padding: 24px;

  .afp-responsive-table {
    .usa-form-group {
      margin-top: 0;

      select {
        margin-top: 0;
      }
    }
    .usa-checkbox {
      margin-top: 0;

      .usa-checkbox__label {
        margin-top: 0.5rem;
      }
    }

    .award-check-all {
      margin-bottom: 28px;
    }
  }

  .contract-line-listing-search-header {
    color: #005ea2;
    font-size: 1.5rem;
  }

  .contract-line-listing-search-item {
    min-width: 15rem;

    .usa-form-group {
      margin-top: 0.5rem;

      .usa-label {
        font-weight: bold;
      }
    }
  }

  .contract-line-listing-search-content {
    margin-top: 1rem;

    &.hidden {
      display: none;
      visibility: hidden;
    }

    .contract-line-listing-search-reset {
      padding-right: 8px;
    }

    .contract-line-listing-search-apply {
      margin-top: 1rem;
    }
  }

  .contract-line-listing-search-content .hidden {
    display: none;
    visibility: hidden;
  }
}

.scheduling-meeting-form-container {
  display: flex;
  width: 90%;

  .scheduled-date-picker {
    width: 15rem;

    .usa-date-picker__calendar__date-picker {
      border: 1px solid;

      .usa-date-picker__calendar__month-label {
        flex: 6 1;
        text-align: center;
      }

      .usa-date-picker__calendar__table {
        th:first-child {
          border: 0px;
          min-width: 2rem;
        }
      }
    }
  }
}

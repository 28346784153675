#contract-line-listing {
  margin-bottom: 4rem;

  .right-align-text {
    text-align: right;
  }
  h1 {
    margin-bottom: 0;
  }

  .usa-form-group {
    margin-top: 0.5rem;

    input[type='number'] {
      text-align: right;
    }
  }
  .direct-container {
    margin-top: 0.5rem;
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: 0;
    size: 16px;
    color: #005ea2;
  }

  label {
    font-weight: bold !important;
  }

  .disabled {
    .usa-input-group {
      background: #f6f6f6;
    }
  }

  .label-bold {
    font-weight: bold !important;
  }
  #close-time-label + div,
  #open-time-label + div {
    max-width: 160px;
  }
  .usa-time-picker {
    max-width: 160px;
  }
  .direct-container {
    max-width: 86px;
  }
  .usa-button--secondary {
    background: #b51d09;
  }
}

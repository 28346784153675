.new-solicitation{
    .statusBadgeContainer{
        margin-top: 2.188rem;
    }
}
.new-solicitation-form {
    .usa-label {
      font-weight: bold;
    }
    .detail-row{
        width: 100%;
        display:flex; 
        .usa-select{
            min-width: 196px; 
        }
    }
    .header {
      display: flex;
      justify-content: space-between;
    }
    .draft-btn {
      height: 2.3rem;
    }
    .solicitation-process {
      display: flex;
      flex-direction: column;
      span {
        padding-top: 1rem;
      }
    }
    .purchase-type {
      display: flex;
      flex-direction: column;
      span {
        padding-top: 1rem;
      }
    }
    .usa-form-group--error {
      margin-left: 0;
    }
    .solicitation-time-select {
      position: relative;
      top: -1rem;
    }
  }
  
  .hide-content{
    display: none;
  }
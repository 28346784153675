.afp-task__details-table {
  width: 100%;
  border-collapse: collapse;
}

.afp-task__details-table th,
.afp-task__details-table td {
  padding: 0.5rem 0;
}

.afp-task-detail__title_tags > * {
  margin-left: 2rem;
  white-space: nowrap;
}

.afp-task-status-tag {
  margin-right: 2rem;
}

.afp-task-detail__task_detail_links {
  margin-top: 2rem;
}

.afp-task-detail__task_detail_links a {
  margin-right: 3rem;
}

.afp-task-detail .afp-details-table {
  max-width: 600px;
}

.afp-task-detail .afp-details-table td {
  padding-left: 2rem;
}

.afp-task-detail__inline_container * {
  display: inline-block;
  margin-right: 0.5rem;
}

.afp-task-detail__header_edit_line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.5rem;
  color: #005087;
}

.afp-task-detail__header_edit {
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.afp-task-detail__header_description_line {
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.afp-task-detail__task_description_text {
  font-size: 1.5rem;
}

.afp-task-detail h1 {
  font-size: 3rem !important;
}

.afp-task-detail .afp-details-table tr,
.afp-task-details__side_col .afp-details-table tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.afp-task-detail .afp-details-table tr {
  border-bottom: 1px solid rgb(202, 202, 202);
}

.afp-task-details__side_col .afp-details-table tr {
  border-bottom: 1px solid rgb(234, 235, 236);
}

.afp-task-detail .afp-details-table tr td,
.afp-task-details__side_col .afp-details-table tr td {
  text-align: right;
}

.afp-details-table .text-no-wrap {
  white-space: normal;
}

.afp-task-status-tag {
  background: #f5f6f7;
  border-radius: 50px;
}

.afp-task-close-button {
  display: flex;
  flex-direction: column;
}

.afp-details-table tr th {
  min-width: 40%;
}

.afp-details-table tr td {
   flex-grow: 1;
}

//revert inherited styling
table td:last-child {
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  // all: revert;
}

.modal {
  /* padding: 0;
  margin: 1rem; */
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  max-width: 60vw;
  width: 60vw;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  padding: 0px;
}

.modal .content {
  margin: 0 20px 20px 0px;
  overflow-y: auto;
  padding-left: 20px;
  overflow-x: hidden;
}

.modal .title {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}

.modal .actions {
  padding: 20px 30px;
  background-color: #f0f0f0;
}

.modalClose {
  margin-top: 20px;
  margin-right: 20px;
}

.modal.modalClose svg {
  margin-right: 0.25rem;
  fill: #71767a;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
}

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  z-index: 2000;
  margin: 1rem;
  padding: 1rem;
  height: auto;
  overflow-y: auto;
}

#main-content a {
  // color: #005ea2;
}

#main-content .white-anchor-text {
  color: white !important;
  white-space: nowrap;
}

.afp-details-table td {
  padding-left: 2rem;
}

.afp-details-table tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

// TODO: Which one we using?

// .afp-details-table tr {
//   border-bottom: 1px solid rgb(202, 202, 202);
// }

.afp-details-table tr {
  border-bottom: 1px solid rgb(234, 235, 236);
}

.afp-details-table tr td,
.afp-task-details__side_col .afp-details-table tr td {
  text-align: right;
}

.afp-details-table .text-no-wrap {
  white-space: normal;
}

.afp-details-table tr th {
  min-width: 40%;
}

.afp-details-table tr td {
  flex-grow: 1;
}

//revert inherited styling
table td:last-child {
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  // all: revert;
}

.afp-multi-tag-spacer {
  padding-left: 0.625rem;
  display: inline-block;
}

.questionnaire-save-btn {
    margin-top: 20px;
}

.vehicle-group-navbar a {
    color: #565c65 !important
}

.vehicle-group-navbar a:active, .vehicle-group-navbar a:focus {
    outline: 0;
    border: none;
}
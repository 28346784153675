.detail-table-links {
  display: flex;
  justify-content: space-between;
}

.afp-details-table tbody tr th:first-child {
  vertical-align: baseline;
}

.afp-details-table tbody tr td {
  vertical-align: baseline;
}

.status {
  background-color: white;
}

.association-table-wrapper .expandable-list {
  display: none;
}

.association-table-wrapper .expand-predetermined {
  display: block;
}

.table-header__predefined-values {
  min-width: 200px;
}

.table-header__no_accessor {
  min-width: 75px;
}

.usa-table--borderless th:first-child {
  min-width: 65px;
}

#predefinedTable_input {
  max-width: 180px;
}

/* Table adjustments */
@media screen and (min-width: 1040px) {
  .association-table-wrapper label[for='select-all-header'] {
    width: 105px;
  }

  .association-table-wrapper .table-header__equipment-code {
    width: 100px;
  }

  .association-table-wrapper .table-header__type {
    width: 106px;
  }

  .association-table-wrapper .table-header__title {
    width: 420px;
  }

  .association-table-wrapper .table-header__input-type {
    width: 240px;
  }
}

.usa-checkbox label {
  text-align: left;
}


.afpTable-cell-wordBreak {
  word-break: break-all;
  width: 100%;
}
.comment table {
  width: 100%;
}
.comment th:first-child {
  border: 0;
  width: 5%;
}
.comment-section {
  display: inline-block;
}

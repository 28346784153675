.assign-qty-input {
    box-sizing: border-box;
    border: 1px solid #5C5C5C;
    height: 39px;
    width: 130px;
    border-radius: 0px;
    margin-left: 10px;
    margin-top: 20px;
    text-indent: 4px;
}

.assign-quantity-header{
    .statusBadgeContainer{
        margin-top: 2.188rem;
    }
}
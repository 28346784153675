.standard-item-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #1b1b1b;
}

.standard-items-sub-component-wrapper {
  padding: 40px 80px;
}

.standard-item-wrapper {
  padding: 1rem 0;
}
.standard-item-separator {
  border-bottom: 1px solid #b1b1b1;
}

.standard-item-status-tag {
  background-color: #9ce18b;
}
.standard-item-status-tag-inactive {
  background-color: #757575;
}

.standard-item-status-tag-text {
  color: #000000;
  font-weight: bold;
  font-size: 11px;
}

// TODO - use mixins from uswds
@media screen and (min-width: 1024px) {
  /*.table-header__standard-item {
    max-width: 3rem;
  }

  .table-header__vehicle-type {
    min-width: 154px;
  }
  .table-header__status {
    max-width: 100px;
  }
  .table-header__title {
    min-width: 320px;
  }
  .table-header__no-accessor-associated-equipment-codes {
    min-width: 100px;
  }*/
}

@media screen and (max-width: 479px) {
  .dropdown-content {
    left: 0;
  }
  .text-right {
    text-align: left;
  }

  .standard-items-sub-component-wrapper {
    padding: 16px;
    background-color: rgb(225, 243, 248);
  }
}

.association-table-wrapper .expandable-list {
  display: none;
}

.association-table-wrapper .expand-predetermined {
display: block;
}

.afpTable-cell-wordBreak {
  word-break: break-all;
  width: 100%;
}

.standard-item-last-row {
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.standard-item-tag-item {
  display: inline-block;
  margin: 2px 4px;
}
.table__filter-icon {
  display: flex;
}

.table-sort-order {
  display: inline-block;
}

.table__sort-icon {
  display: inline-block;
  width: 1.5rem;
  text-align: center;
  padding-top: 0.1rem;
}

.table-filters td {
  background: #eff6fb !important;
  padding: 10px;
  border-top: 3px solid #000000;
  border-bottom: none;
}
.table-header {
  display: flex;
}

.typeAheadWrapper {
  width: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-right: 42px;
  .usa-form-group {
    margin-top: 0;
  }
}

.searchInputWrapper {
  background: #eff2f4;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  height: 71px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 3px solid #000000;
}

.customInput {
  height: 39px;
  background: #ffffff;
  border: 1px solid #5c5c5c;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}

.bodyWrapper {
  box-sizing: border-box;
  height: 71px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #a9aeb1;
}

.tableBodyWrapper {
  background: #ffffff;
  border-top: 1px solid #a9aeb1;
  border-bottom: 1px solid #a9aeb1;
  box-sizing: border-box;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitleWrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.chassisWidth {
  width: 1336.34px;
}

.makeAndModelWidth {
  width: 850px;
}

.introWrapper {
  width: 792px;
  font-style: normal;
  font-size: 16px;
  line-height: 162%;
}

.titleHeader {
  font-weight: bold;
  font-size: 15px;
  line-height: 135%;
  color: #1b1b1b;
  margin-right: 4px;
}

.Status {
  margin-left: 24px;
}

.MakeChassis {
  width: 130px;
}

.Make {
  width: 160px;
}

.Model {
  width: 170px;
}
.Model-text {
  margin-left: 15px;
}

.Actions {
  margin-right: 28px;
  padding-left: 5px;
}

.titleWrapper {
  width: 156px;
  margin-right: 42px;
}

.bodyTextWrapper {
  width: 218px;
  margin-right: 42px;
}

.titleInnerWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}

.statusWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.makeAndModelActions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  margin-right: 24px;
}

.firstTitleCss {
  margin-left: 24px;
}

.lastTitleCss {
  margin-right: 24px;
}

.innerTittle {
  width: 218px;
  margin-right: 60px;
}

.afp-table-header__sort-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-weight: 700;
}

.addBtn {
  background: #ffffff;
  border: 2px solid #005ea2;
  box-sizing: border-box;
  border-radius: 3px;
  width: 80px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 24px;
}

.deleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}

.btnTxt {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 135%;
  color: #005ea2;
}

.btnIcon {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #005ea2;
  margin-right: 5.5px;
}

.intentToBidWrapper {
  margin-bottom: 50px;
}

.intentionToBidYesNoWrapper {
  display: flex;
  max-width: 134px;
  justify-content: space-between;
}

.intentionToBidTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 135%;
  width: 900px;
  margin-top: 30px;
}

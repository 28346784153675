.edit-button {
  position: absolute;
  right: 20px;
}

.catalog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-table-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#contract-search-table-expanded-row {
  .item-separator {
    border-bottom: 1px solid #b1b1b1;
  }

  .item-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #1b1b1b;
  }

  .item-wrapper {
    padding: 1rem 0;
  }
}
#contract-history {
  .contract-history-table-subrow {
    max-width: 60rem;

    .afp-responsive-table {
      th {
        padding: 0.5rem 0.75rem !important;
        font-weight: bold;
      }
      td {
        padding: 1rem 0.75rem 1rem 1.875rem !important;
      }
    }
  }

  label {
    font-weight: bold !important;
  }
}

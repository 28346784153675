.columns-wrapper{
    .header{
        text-transform: uppercase;
        font-weight: 900;
        .text-checkbox{
            text-transform: none;
        }
    }
    .afp-chip{
        text-overflow: ellipsis;
        word-wrap: none;
    }
}
// .select-standard-item-accordion{
//     .usa-accordion__content{
//         border: 2px solid rgb(137, 38, 8);
//         height: auto;
//         max-height: 31.25rem; 
//         overflow-y: scroll;
//     }
// }
.audienceList{
    height: auto;
    width: fit-content;
    max-height: 31.25rem; 
    overflow-y: scroll;
}
/* Dropdown Button */
.dropdown-button {
  /* add button style if necessary */
  box-shadow: none;
}

/* Dropdown button on hover & focus */
.dropdown-button:hover,
.dropdown-button:focus {
  box-shadow: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  /* right: 0; */
  z-index: 1;
  min-width: 220px;
  background: #ffffff;
  border: 1px solid #dcdee0;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.128223);
}

.dropdown-content button {
  margin-left: 5px;
  box-shadow: none;
  text-decoration: none;
  font-weight: 100;
}

.dropdown-content button:hover {
  box-shadow: none;
  text-decoration: underline;
}

.dropdown-content-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.add-document-icon {
  margin-right: 10px;
}

button.attachment-delete-link {
  color: #b51d09;
}
.show {
  display: block;
}

button.action-button {
  margin-right: 0;
}

.usa-file-input {
  min-width: 562px;
}

.attachment-description {
  width: 100%;
}

.attachment-load-more {
  margin: 40px 0px;
}

.attachment-load-more-icon {
  margin-left: 10px;
}

.attachment-load-more-hide {
  display: none;
}

.attachment-modal .modal-close-button {
  padding: 1rem;
}

.afp-attachment-table {
  table-layout: fixed;
  width: 100%;
}

.afp-attachment-table th,
.afp-attachment-table td {
  vertical-align: top;
  word-break: break-word;
}

.task-manager-updatedat {
  white-space: nowrap;
}

.task-manager-name {
  white-space: nowrap;
}

.task-manager-createdByUser {
  white-space: nowrap;
}

.task-manager-description {
  white-space: nowrap;
}

.attachment-add-button {
  float: right;
  margin: 8px 0 0 0;
}

.attachment-item-wrapper {
  padding: 1rem 0;
}

.attachment-item-separator {
  border-bottom: 1px solid #b1b1b1;
}
.attachment-item-separator:last-child {
  border-bottom: none;
}

.attachment-item-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #1b1b1b;
}

.attachment-add-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.table-header__document-name {
  min-width: 200px;
}

.table-header__description {
  min-width: 300px;
}

.comment-actions {
  display: flex;
  justify-content: flex-end;
}

.diffmod  {
  background-color: #FEE685;
  padding-left: 0.2rem;
}

.diffins  {
  background-color: #FEE685;
  padding-left: 0.2rem;
}

.diffdel {
  background-color: #FEE685;
  padding-left: 0.2rem;
}

.association-table-wrapper .expandable-list {
  display: none;
}

.association-table-wrapper .expand-predetermined {
  display: block;
}

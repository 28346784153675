.standard-section-header {
  font-size: 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 0;
}

.standard-page-description {
 font-size: 1.5em;
 max-width: 800px;
}

.standard-h3 {
  text-transform: uppercase;
}

.standard-h4 {
  text-transform: uppercase;
}
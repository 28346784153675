.repliesWrapper {
  border-left: 1px solid #73b3e7;
}
.comment-container {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.more-btn {
  display: flex;
}
.reply-main {
  display: flex;
}
.commenting-period-info {
  .commenting-period-info-header {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
  }
  th,
  td {
    background-color: transparent;
  }
}

.is-loading-indicator {
  width: 100%;
  display: flex;
  justify-content: center;
}

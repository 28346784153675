.c-typeahead-container {
  position: relative;
  background-color: #fff;

  .prefix-icon {
    position: absolute;
    left: 8px;
    top: 1.2rem;
  }
}

.c-type-ahead-prompt {
  background: #fff;
  border: 1px solid #000;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 3;
  margin-bottom: -1px;
  min-width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  padding: 5px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;


    li {
      padding: 5px;
      cursor: pointer;
      border-bottom: 1px solid #000;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background: #d9e8f6;
      }

      &.selected {
        background: #d9e8f6;
      }
    }
  }

}
#contract-header {
  .right-align-text {
    text-align: right;
  }

  .usa-form-group {
    input[type='text'],
    input[type='number'] {
      width: 80%;
    }

    input[type='number'] {
      text-align: right;
    }
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: 0;
    size: 16px;
    color: #005ea2;
  }

  .col {
    label {
      font-weight: bold;
    }
  }

  .disabled {
    .usa-input-group {
      background: #f6f6f6;
    }
  }
  .secondary-container {
    padding: 30px 20px;
    background: #f9f9f9;
    margin-top: 4px;
    margin-bottom: 60px;
  }
}

.phone-fax-wrap {
  .grid-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  input {
    margin-top: 24px;
  }
}
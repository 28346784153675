.contractline-doc-attachment-modal {
  min-width: 630px;
}

.contractline-doc-attachment-form-fields {
  padding: 0 24px;
}

.contractline-doc-attachment-modal .modal-inner,
.contractline-doc-attachment-modal .content,
.contractline-image-attachment-modal .modal-inner,
.contractline-image-attachment-modal .content {
  padding: 0;
  margin: 0;
}

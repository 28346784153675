#update-line-listing {
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000; 
    }
  
    .modal-content {
      background: white;
      padding: 20px;
      border-radius: 8px;
      max-width: 600px;
      width: 100%;
      max-height: 90vh; 
      overflow-y: auto; 
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
  }
  
  .form-container {
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    background: #eff6fb;
    padding: 24px;
  }

  .review-item-container {
    ul {
        background: #eff6fb;
        padding: 24px;
        padding-left: 64px;
    }
  

  }

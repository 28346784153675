.report-date-range-form {
  .usa-form-group {
    margin-top: 0;
  }
  .usa-form-group--error {
    margin-left: -0.375rem;

    .usa-date-picker__external-input {
      outline: 0.25rem solid #b50909;
    }
  }
}

.report-contract-periods-form {
  .usa-form-group {
    margin-top: 0;
  }
}

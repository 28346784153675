.document-preview-select {
  min-width: 28.125rem;
}

@media only screen and (max-width: 1023px) {
  .document-preview-mobile {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .document-preview-select {
    min-width: 18.75rem;
  }
}
